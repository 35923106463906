var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center align-items-center vh-100",
        },
        [
          _c("b-spinner", {
            attrs: { variant: "primary", label: "Carregando..." },
          }),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitUpdatePayment.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { header: "Pagamento" } },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "total" } }, [
                        _vm._v("Total"),
                      ]),
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "form-control",
                            attrs: { placeholder: "0,00" },
                            model: {
                              value: _vm.payment.total_final,
                              callback: function ($$v) {
                                _vm.$set(_vm.payment, "total_final", $$v)
                              },
                              expression: "payment.total_final",
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Data de Vencimento")]),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            config: {
                              altInput: true,
                              dateFormat: "Y-m-d",
                              altFormat: "d/m/Y",
                              locale: "pt",
                            },
                          },
                          model: {
                            value: _vm.payment.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.payment, "date", $$v)
                            },
                            expression: "payment.date",
                          },
                        }),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _c("i", { staticClass: "bi bi-calendar" }),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group mt-1" },
                      [
                        _c("label", [_vm._v("Banco")]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "title",
                              clearable: false,
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsBankAccounts,
                              placeholder: "Conta bancária",
                            },
                            model: {
                              value: _vm.payment.bank_account,
                              callback: function ($$v) {
                                _vm.$set(_vm.payment, "bank_account", $$v)
                              },
                              expression: "payment.bank_account",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "form-group mt-1" }, [
                      _c("label", [_vm._v(" Histórico ")]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payment.description,
                            expression: "payment.description",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { disabled: false, name: "", rows: "3" },
                        domProps: { value: _vm.payment.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.payment,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-group mt-1" },
                      [
                        _c("label", [_vm._v(" Histórico PC ")]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              searchable: true,
                              clearable: false,
                              label: "title",
                              options: _vm.optionsPlans,
                            },
                            model: {
                              value: _vm.plan,
                              callback: function ($$v) {
                                _vm.plan = $$v
                              },
                              expression: "plan",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "success" } },
                    [_vm._v("Salvar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-form", { ref: "formFilter", staticClass: "m-0 mt-1" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c("b-card-header", [
                        _c(
                          "div",
                          [
                            _c("b-card-title", { staticClass: "mb-1" }, [
                              _vm._v(" Dados gerais "),
                            ]),
                            _c("b-card-sub-title", [
                              _vm._v(
                                " Os dados inseridos abaixo serão utilizados ao emitir a nota fiscal. "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(" Tipo de documento "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      disabled: true,
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                    },
                                    model: {
                                      value: _vm.item.document_type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "document_type", $$v)
                                      },
                                      expression: "item.document_type",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-8" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-md-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Fornecedor "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      label: "title",
                                      disabled: true,
                                      "item-text": "title",
                                      "item-value": "code",
                                    },
                                    model: {
                                      value: _vm.item.supplier,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "supplier", $$v)
                                      },
                                      expression: "item.supplier",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "form-group mb-md-0" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" Nº Documento "),
                                _c("i", {
                                  staticClass: "bi bi-question-circle",
                                }),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.document_number,
                                    expression: "item.document_number",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { disabled: true, type: "text" },
                                domProps: { value: _vm.item.document_number },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "document_number",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-md-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Categoria "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      disabled: true,
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                    },
                                    model: {
                                      value: _vm.item.category,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "category", $$v)
                                      },
                                      expression: "item.category",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-5" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Histórico PC "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      disabled: true,
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                    },
                                    model: {
                                      value: _vm.item.plan,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "plan", $$v)
                                      },
                                      expression: "item.plan",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-7" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-md-0" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Conta bancária "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      disabled: true,
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                    },
                                    model: {
                                      value: _vm.item.bank_account,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "bank_account", $$v)
                                      },
                                      expression: "item.bank_account",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c("b-card-header", [
                        _c(
                          "div",
                          [
                            _c("b-card-title", { staticClass: "mb-1" }, [
                              _vm._v("Total e Pagamentos "),
                            ]),
                            _c("b-card-sub-title", [
                              _vm._v(
                                " Abaixo serão listadas os próximos pagamentos. "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "b-card-body",
                        { staticClass: "pl-2 pr-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-row",
                              staticStyle: { "align-items": "center" },
                            },
                            [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mb-md-0" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Total "),
                                    ]),
                                    _c(
                                      "money",
                                      _vm._b(
                                        {
                                          staticClass: "form-control",
                                          attrs: { disabled: true },
                                          model: {
                                            value: _vm.item.total,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item, "total", $$v)
                                            },
                                            expression: "item.total",
                                          },
                                        },
                                        "money",
                                        _vm.money,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mb-md-0" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Pagamentos "),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: ["####"],
                                          expression: "['####']",
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.installments,
                                          expression: "item.installments",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.installments.$error,
                                      },
                                      attrs: { disabled: true, type: "tel" },
                                      domProps: {
                                        value: _vm.item.installments,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "installments",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mb-md-0 mb-0" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Vencimento "),
                                      _c("i", {
                                        staticClass: "bi bi-question-circle",
                                      }),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.installment_due,
                                          expression: "item.installment_due",
                                        },
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: ["##"],
                                          expression: "['##']",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.installment_due.$error,
                                      },
                                      attrs: {
                                        disabled: true,
                                        max: "31",
                                        placeholder: "1 à 31",
                                      },
                                      domProps: {
                                        value: _vm.item.installment_due,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "installment_due",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c("hr"),
                          _vm.installments.length
                            ? _c("b-table", {
                                staticClass: "position-relative",
                                staticStyle: {
                                  transition: "background-color 0.5s ease",
                                },
                                attrs: {
                                  "sticky-header": "",
                                  items: _vm.installments,
                                  responsive: "",
                                  fields: _vm.installmentsTable,
                                  "show-empty": "",
                                  "empty-text": "Nenhum registro encontrado",
                                  "tbody-tr-class": _vm.rowClass,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(installment)",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "strong",
                                            { staticClass: "relative" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.installment) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(date)",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("flat-pickr", {
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid":
                                                _vm.$v.item.all_installments
                                                  .$each[index].date.$error,
                                            },
                                            attrs: {
                                              config: {
                                                altInput: true,
                                                dateFormat: "Y-m-d",
                                                altFormat: "d/m/Y",
                                                locale: "pt",
                                              },
                                              disabled: true,
                                            },
                                            model: {
                                              value: item.date,
                                              callback: function ($$v) {
                                                _vm.$set(item, "date", $$v)
                                              },
                                              expression: "item.date",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(total)",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "input-group mb-0" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "input-group-prepend",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "input-group-text",
                                                      attrs: {
                                                        id: "basic-addon1",
                                                      },
                                                    },
                                                    [_vm._v("R$")]
                                                  ),
                                                ]
                                              ),
                                              _c("money", {
                                                staticClass: "form-control",
                                                class: {
                                                  "is-invalid":
                                                    _vm.$v.item.all_installments
                                                      .$each[index].total
                                                      .$error,
                                                },
                                                attrs: { disabled: true },
                                                model: {
                                                  value: item.total,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "total", $$v)
                                                  },
                                                  expression: "item.total",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  716162266
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-row mt-3 mb-2" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("h4", { staticClass: "mb-0" }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                          staticStyle: { "font-size": "0.9rem" },
                        }),
                        _vm._v(" Rateio "),
                      ]),
                    ]),
                  ]),
                  _vm._l(
                    _vm.item.strategic_units,
                    function (strategicElement, i) {
                      return _c(
                        "b-card",
                        {
                          key: "business-units-key-" + i,
                          staticClass: "mb-0 card-header-custom-actions mb-1",
                          attrs: { "header-tag": "header" },
                        },
                        [
                          _c("b-card-text", { staticClass: "mt-0" }, [
                            _c(
                              "div",
                              { staticClass: "form-row align-items-center" },
                              [
                                _c("div", { staticClass: "col-md-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mb-0" },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          attrs: {
                                            label: "title",
                                            "item-text": "title",
                                            "item-value": "code",
                                            disabled: true,
                                          },
                                          model: {
                                            value:
                                              strategicElement.strategic_unit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                strategicElement,
                                                "strategic_unit",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "strategicElement.strategic_unit",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "no-options" },
                                              slot: "no-options",
                                            },
                                            [
                                              _vm._v(
                                                " Nenhum registro encontrado "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "col-md-3" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group mb-0 text-center",
                                    },
                                    [
                                      _c("money", {
                                        staticClass: "form-control",
                                        attrs: { disabled: true },
                                        model: {
                                          value: strategicElement.total,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              strategicElement,
                                              "total",
                                              $$v
                                            )
                                          },
                                          expression: "strategicElement.total",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "col-md-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group mb-0" },
                                    [
                                      _c(
                                        "money",
                                        _vm._b(
                                          {
                                            staticClass: "form-control",
                                            attrs: { disabled: true },
                                            model: {
                                              value: strategicElement.percent,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  strategicElement,
                                                  "percent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "strategicElement.percent",
                                            },
                                          },
                                          "money",
                                          _vm.percentFormat,
                                          false
                                        )
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-12" },
                                  [
                                    _c(
                                      "b-card-text",
                                      {
                                        staticClass: "card-strategic p-1 mt-1",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-row",
                                            staticStyle: {
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-12" },
                                              [
                                                _c(
                                                  "h5",
                                                  { staticClass: "mb-0" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "text-danger bi bi-record-circle",
                                                      staticStyle: {
                                                        "font-size": "0.9rem",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " Unidades de negócio "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          strategicElement.business,
                                          function (businessElement, index) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  "business-strategic-key-" +
                                                  index,
                                                staticClass:
                                                  "form-row align-items-center mt-1",
                                                class: {
                                                  "mb-1":
                                                    index !==
                                                    strategicElement.business
                                                      .length -
                                                      1,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-7" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "v-select",
                                                          {
                                                            attrs: {
                                                              label: "title",
                                                              "item-text":
                                                                "title",
                                                              "item-value":
                                                                "code",
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                businessElement.business_unit,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    businessElement,
                                                                    "business_unit",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "businessElement.business_unit",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  slot: "no-options",
                                                                },
                                                                slot: "no-options",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Nenhum registro encontrado "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group mb-0",
                                                      },
                                                      [
                                                        _c(
                                                          "money",
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                disabled: true,
                                                              },
                                                              model: {
                                                                value:
                                                                  businessElement.percent,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      businessElement,
                                                                      "percent",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "businessElement.percent",
                                                              },
                                                            },
                                                            "money",
                                                            _vm.percentFormat,
                                                            false
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-md-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group mb-0",
                                                      },
                                                      [
                                                        _c("money", {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            disabled: true,
                                                          },
                                                          model: {
                                                            value:
                                                              businessElement.total,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                businessElement,
                                                                "total",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "businessElement.total",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c("b-card-header", [
                        _c(
                          "div",
                          [
                            _c("b-card-title", { staticClass: "mb-0" }, [
                              _vm._v(" Histórico "),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.description,
                                expression: "item.description",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: true, name: "", rows: "3" },
                            domProps: { value: _vm.item.description },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "description",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c("b-card-header", [
                        _c(
                          "div",
                          [
                            _c("b-card-title", { staticClass: "mb-1" }, [
                              _vm._v(" Tags "),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "b-card-body",
                        { staticClass: "pl-2 pr-2" },
                        [
                          _c("b-form-tags", {
                            staticClass: "form-control mb-1",
                            attrs: {
                              separator: ",;",
                              "no-add-on-enter": "",
                              rows: "3",
                              disabled: true,
                            },
                            model: {
                              value: _vm.item.tags,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "tags", $$v)
                              },
                              expression: "item.tags",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                id: "confirm-new-payment-modal",
                title: "Gerar Novo Pagamento?",
                "hide-footer": "",
                centered: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("h1", [_vm._v("Gerar novo pagamento?")]),
                  _c("p", [_vm._v("O valor foi reduzido.")]),
                  _c("p", [
                    _c("strong", [_vm._v("Total Restante:")]),
                    _vm._v(
                      " R$ " + _vm._s(_vm.remainingAmount.toFixed(2)) + " "
                    ),
                  ]),
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(" Data de Vencimento "),
                  ]),
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Data de Vencimento",
                      config: {
                        altInput: true,
                        dateFormat: "Y-m-d",
                        altFormat: "d/m/Y",
                        locale: "pt",
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                  _c("label", { staticClass: "mt-2", attrs: { for: "" } }, [
                    _vm._v(" Banco "),
                  ]),
                  _c(
                    "v-select",
                    {
                      attrs: {
                        label: "title",
                        clearable: false,
                        "item-text": "title",
                        "item-value": "code",
                        options: _vm.optionsBankAccounts,
                        placeholder: "Conta bancária",
                      },
                      model: {
                        value: _vm.bank_account,
                        callback: function ($$v) {
                          _vm.bank_account = $$v
                        },
                        expression: "bank_account",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v(" Nenhum registro encontrado ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column justify-content-end mt-3" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-1",
                      attrs: { variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm(true)
                        },
                      },
                    },
                    [_vm._v(" Gerar um novo pagamento com valor restante ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mb-1",
                      attrs: { variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleConfirm(false)
                        },
                      },
                    },
                    [_vm._v(" Atualizar e não gerar novo pagamento ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "light" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }