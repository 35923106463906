<template>
  <div
    v-if="loading"
    class="d-flex justify-content-center align-items-center vh-100"
  >
    <b-spinner variant="primary" label="Carregando..."></b-spinner>
  </div>
  <div v-else>
    <b-form @submit.prevent="submitUpdatePayment">
      <b-card header="Pagamento" class="mb-1">
        <div class="form-group">
          <label for="total">Total</label>
          <money
            class="form-control"
            v-model="payment.total_final"
            placeholder="0,00"
            v-bind="money"
          ></money>
        </div>
        <div class="form-group">
          <label>Data de Vencimento</label>

          <div class="input-group">
            <flat-pickr
              v-model="payment.date"
              class="form-control"
              :config="{
                altInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                locale: 'pt',
              }"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="bi bi-calendar"></i>
              </span>
            </div>
          </div>

          <div class="form-group mt-1">
            <label>Banco</label>
            <v-select
              label="title"
              :clearable="false"
              item-text="title"
              item-value="code"
              :options="optionsBankAccounts"
              v-model="payment.bank_account"
              placeholder="Conta bancária"
            >
              <span slot="no-options"> Nenhum registro encontrado </span>
            </v-select>
          </div>
          <div class="form-group mt-1">
            <label> Histórico </label>
            <textarea
              :disabled="false"
              name=""
              class="form-control"
              v-model="payment.description"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group mt-1">
            <label> Histórico PC </label>
            <v-select
              :searchable="true"
              :clearable="false"
              label="title"
              :options="optionsPlans"
              v-model="plan"
            >
              <span slot="no-options"> Nenhum registro encontrado </span>
            </v-select>
          </div>
        </div>
        <b-button type="submit" variant="success">Salvar</b-button>
      </b-card>
    </b-form>
    <b-form class="m-0 mt-1" ref="formFilter">
      <div class="form-row">
        <div class="col-md-8">
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Dados gerais </b-card-title>
                <b-card-sub-title>
                  Os dados inseridos abaixo serão utilizados ao emitir a nota
                  fiscal.
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for=""> Tipo de documento </label>
                    <v-select
                      :disabled="true"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.document_type"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Fornecedor
                    </label>
                    <v-select
                      label="title"
                      :disabled="true"
                      item-text="title"
                      item-value="code"
                      v-model="item.supplier"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-md-0">
                    <label for="">
                      Nº Documento
                      <i class="bi bi-question-circle"></i>
                    </label>
                    <input
                      :disabled="true"
                      type="text"
                      v-model="item.document_number"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i> Categoria
                    </label>
                    <v-select
                      :disabled="true"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.category"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Histórico PC
                    </label>
                    <v-select
                      :disabled="true"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.plan"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Conta bancária
                    </label>
                    <v-select
                      :disabled="true"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.bank_account"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1">Total e Pagamentos </b-card-title>
                <b-card-sub-title>
                  Abaixo serão listadas os próximos pagamentos.
                </b-card-sub-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-row" style="align-items: center">
                <div class="col-md-4">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Total
                    </label>
                    <money
                      :disabled="true"
                      class="form-control"
                      v-bind="money"
                      v-model="item.total"
                    ></money>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-md-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Pagamentos
                    </label>
                    <input
                      :disabled="true"
                      type="tel"
                      v-mask="['####']"
                      :class="{ 'is-invalid': $v.item.installments.$error }"
                      v-model="item.installments"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group mb-md-0 mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Vencimento
                      <i class="bi bi-question-circle"></i>
                    </label>
                    <input
                      :disabled="true"
                      class="form-control"
                      :class="{ 'is-invalid': $v.item.installment_due.$error }"
                      v-model="item.installment_due"
                      v-mask="['##']"
                      max="31"
                      placeholder="1 à 31"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <b-table
                v-if="installments.length"
                sticky-header
                :items="installments"
                responsive
                :fields="installmentsTable"
                show-empty
                empty-text="Nenhum registro encontrado"
                class="position-relative"
                style="transition: background-color 0.5s ease"
                :tbody-tr-class="rowClass"
              >
                <template #cell(installment)="{ item }">
                  <strong class="relative">
                    {{ item.installment }}
                  </strong>
                </template>

                <template #cell(date)="{ item, index }">
                  <flat-pickr
                    v-model="item.date"
                    class="form-control"
                    :config="{
                      altInput: true,
                      dateFormat: 'Y-m-d',
                      altFormat: 'd/m/Y',
                      locale: 'pt',
                    }"
                    :disabled="true"
                    :class="{
                      'is-invalid':
                        $v.item.all_installments.$each[index].date.$error,
                    }"
                  />
                </template>

                <template #cell(total)="{ item, index }">
                  <div class="input-group mb-0">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">R$</span>
                    </div>
                    <money
                      class="form-control"
                      v-model="item.total"
                      :disabled="true"
                      :class="{
                        'is-invalid':
                          $v.item.all_installments.$each[index].total.$error,
                      }"
                    />
                  </div>
                </template>
              </b-table>
            </b-card-body>
          </b-card>

          <div class="form-row mt-3 mb-2">
            <div class="col-md-12">
              <h4 class="mb-0">
                <i
                  class="text-danger bi bi-record-circle"
                  style="font-size: 0.9rem"
                ></i>
                Rateio
              </h4>
            </div>
          </div>

          <b-card
            class="mb-0 card-header-custom-actions mb-1"
            header-tag="header"
            v-for="(strategicElement, i) in item.strategic_units"
            :key="`business-units-key-${i}`"
          >
            <b-card-text class="mt-0">
              <div class="form-row align-items-center">
                <div class="col-md-6">
                  <div class="form-group mb-0">
                    <v-select
                      label="title"
                      v-model="strategicElement.strategic_unit"
                      item-text="title"
                      item-value="code"
                      :disabled="true"
                    >
                      <span slot="no-options">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-0 text-center">
                    <money
                      class="form-control"
                      v-model="strategicElement.total"
                      :disabled="true"
                    ></money>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group mb-0">
                    <money
                      class="form-control"
                      v-bind="percentFormat"
                      v-model="strategicElement.percent"
                      :disabled="true"
                    ></money>
                  </div>
                </div>

                <div class="col-md-12">
                  <b-card-text class="card-strategic p-1 mt-1">
                    <div class="form-row" style="align-items: center">
                      <div class="col-md-12">
                        <h5 class="mb-0">
                          <i
                            class="text-danger bi bi-record-circle"
                            style="font-size: 0.9rem"
                          ></i>
                          Unidades de negócio
                        </h5>
                      </div>
                    </div>

                    <div
                      class="form-row align-items-center mt-1"
                      :class="{
                        'mb-1': index !== strategicElement.business.length - 1,
                      }"
                      v-for="(
                        businessElement, index
                      ) in strategicElement.business"
                      :key="`business-strategic-key-${index}`"
                    >
                      <div class="col-md-7">
                        <div class="form-group mb-0">
                          <v-select
                            label="title"
                            v-model="businessElement.business_unit"
                            item-text="title"
                            item-value="code"
                            :disabled="true"
                          >
                            <span slot="no-options">
                              Nenhum registro encontrado
                            </span>
                          </v-select>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group mb-0">
                          <money
                            class="form-control"
                            :disabled="true"
                            v-model="businessElement.percent"
                            v-bind="percentFormat"
                          ></money>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group mb-0">
                          <money
                            class="form-control"
                            :disabled="true"
                            v-model="businessElement.total"
                          ></money>
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-md-4">
          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-0"> Histórico </b-card-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <div class="form-group mb-0">
                <textarea
                  :disabled="true"
                  name=""
                  class="form-control"
                  v-model="item.description"
                  rows="3"
                ></textarea>
              </div>
            </b-card-body>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header>
              <div>
                <b-card-title class="mb-1"> Tags </b-card-title>
              </div>
            </b-card-header>
            <b-card-body class="pl-2 pr-2">
              <b-form-tags
                separator=",;"
                no-add-on-enter
                class="form-control mb-1"
                v-model="item.tags"
                rows="3"
                :disabled="true"
              />
            </b-card-body>
          </b-card>
        </div>
      </div>
    </b-form>

    <b-modal
      id="confirm-new-payment-modal"
      title="Gerar Novo Pagamento?"
      hide-footer
      centered
    >
      <div class="text-center">
        <h1>Gerar novo pagamento?</h1>
        <p>O valor foi reduzido.</p>
        <p>
          <strong>Total Restante:</strong> R$ {{ remainingAmount.toFixed(2) }}
        </p>
        <label for=""> Data de Vencimento </label>
        <flat-pickr
          v-model="date"
          class="form-control"
          placeholder="Data de Vencimento"
          :config="{
            altInput: true,
            dateFormat: 'Y-m-d',
            altFormat: 'd/m/Y',
            locale: 'pt',
          }"
        />
        <label for="" class="mt-2"> Banco </label>
        <v-select
          label="title"
          :clearable="false"
          item-text="title"
          item-value="code"
          :options="optionsBankAccounts"
          v-model="bank_account"
          placeholder="Conta bancária"
        >
          <span slot="no-options"> Nenhum registro encontrado </span>
        </v-select>
      </div>
      <div class="d-flex flex-column justify-content-end mt-3">
        <b-button variant="success" class="mb-1" @click="handleConfirm(true)">
          Gerar um novo pagamento com valor restante
        </b-button>
        <b-button variant="success" class="mb-1" @click="handleConfirm(false)">
          Atualizar e não gerar novo pagamento
        </b-button>
        <b-button variant="light" @click="handleCancel"> Cancelar </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BCardBody,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BCardFooter,
  BFormTags,
  BTable,
  BInputGroupAppend,
  BButton,
  BCardText,
  BModal,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import { Money } from "v-money";

import { maxValue, minValue, required } from "vuelidate/lib/validators";

import vSelect from "vue-select";
import "swiper/css/swiper.css";

import moment from "moment";

export default {
  components: {
    BForm,
    BCard,
    BModal,
    BTable,
    BCardHeader,
    BCardBody,
    BCardTitle,
    VBTooltip,
    BCardSubTitle,
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    BCardFooter,
    BInputGroupAppend,
    vSelect,
    BFormTags,
    Money,
    flatPickr,
    BButton,
    BCardText,
  },
  data() {
    return {
      submited: false,
      formattedDate: "",
      date: "",
      loading: false,
      optionsBankAccounts: [],
      optionsPlans: [],
      remainingAmount: 0,
      originalPaymentTotal: 0,
      bank_account: "",
      plan: "",
      item: {
        tags: [],
        supplier: "",
        issue_date: "",
        total: "",
        due_date: "",
        category: "",
        document_number: "",
        occurrence: "",
        note: "",
        document_type: "",
        plan: "",
        description: "",
        strategic_units: [],
        installments: "",
        installment_first: "",
        installment_due: "",
        bank_account: "",
      },
      installmentsTable: [
        {
          key: "installment",
          label: "Pag. Nº",
          class: "text-center",
          thStyle: "width: 30px",
        },
        {
          key: "date",
          label: "Vencimento",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "total",
          label: "Valor",
          class: "text-center",
          thStyle: "width: 150px",
        },
      ],
      payment: {},
      installments: [],
      optionsStatus: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: ".",
        thousands: ".",
        prefix: "",
        suffix: "%",
        precision: 2,
        masked: false,
      },
    };
  },
  validations: {
    item: {
      supplier: {
        required,
      },
      total: {
        required,
      },
      plan: {
        required,
      },
      bank_account: {
        required,
      },
      category: {
        required,
      },
      installments: {
        required,
        minValue: minValue(1),
      },
      installment_due: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(31),
      },
      strategic_units: {
        $each: {
          strategic_unit: {
            required,
          },
          percent: {
            required,
          },
          total: {
            required,
          },
        },
      },
      all_installments: {
        $each: {
          total: {
            required,
            minValue: minValue(0.1),
          },
          date: {
            required,
          },
        },
      },
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.uuid === this.$route.params.payment_uuid) return "table-edit";
    },
    async getOrderPurchase() {
      this.$store
        .dispatch("OrderPurchase/edit", this.$route.params.uuid)
        .then((res) => {
          this.item = res;
          this.payment = {
            ...this.payment,
            description: res.description,
          };
          this.plan = res.plan;
          this.installments = res.all_installments.map((item) => {
            return {
              ...item,
              checkbox: false,
            };
          });

          this.item.installments = this.installments.length;
        });
    },
    async getPayment() {
      await this.$store
        .dispatch(
          "OrderPurchase/getPaymentByUuid",
          this.$route.params.payment_uuid
        )
        .then((res) => {
          this.payment = res;
          this.date = moment(res.date).format("YYYY-MM-DD");
          this.bank_account = res.bank_account;
        });
    },
    async createNewPayment(total) {
      await this.$store
        .dispatch("OrderPurchase/createNewPayment", {
          payment: {
            ...this.payment,
            date: this.date,
            bank_account: this.bank_account,
          },
          total,
        })
        .then(() => {
          this.notify(
            "Novo pagamento gerado com sucesso!",
            "CheckIcon",
            "success",
            ""
          );
        })
        .catch(() => {
          this.notify(
            "Erro ao gerar novo pagamento.",
            "ErrorIcon",
            "danger",
            ""
          );
        });
    },
    async updateInstallment() {
      await this.$store
        .dispatch("OrderPurchase/updatePaymentInstallment", {
          uuid: this.$route.params.payment_uuid,
          data: {
            ...this.payment,
            plan: this.plan,
          },
        })
        .then((res) => {
          this.notify(
            "Operação realizada com sucesso!",
            "CheckIcon",
            "success",
            ""
          );
          this.$router.push({ name: "orders-purchases-payments-list" });
        });
    },
    async submitUpdatePayment() {
      if (this.payment.total_final < this.originalPaymentTotal) {
        this.remainingAmount =
          this.originalPaymentTotal - this.payment.total_final;
        this.formattedDate = moment(this.payment.date).format("DD/MM/YYYY");

        this.$bvModal.show("confirm-new-payment-modal");
      } else {
        await this.updateInstallment();
      }
    },
    async handleConfirm(createNewPayment) {
      if (createNewPayment) {
        await this.createNewPayment(this.remainingAmount);
      }
      await this.updateInstallment();
      this.$bvModal.hide("confirm-new-payment-modal");
    },
    handleCancel() {
      this.$bvModal.hide("confirm-new-payment-modal");
    },
    async fetchBankAccounts(term = "") {
      await this.$store
        .dispatch("BankAccount/forSelect", { term })
        .then((res) => {
          this.optionsBankAccounts = res;
        });
    },
    async fetchPlans() {
      await this.$store.dispatch("OrderPurchase/forSelectPlans").then((res) => {
        this.optionsPlans = res;
      });
    },
  },

  async mounted() {
    try {
      this.loading = true;
      await this.fetchPlans();
      await this.fetchBankAccounts();
      await this.getOrderPurchase();
      await this.getPayment();
      this.originalPaymentTotal = this.payment.total_final;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.card-strategic {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 7%);
  border-radius: 0.428rem;
  border: 1px solid #f3f3f3;
}

.btn-trash {
  text-align: center;
  padding: 0px !important;
  height: 44px;
}

.table-edit {
  background-color: #e5e5e5;
  border-radius: 15px;

  .form-control {
    background: #fff !important;
  }

  input:disabled {
    background: #efefef !important;
    cursor: not-allowed;
  }
}
</style>
